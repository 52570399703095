import type { Coverage } from "./coverage.dto";
import type { Plan } from "./plan.dto";
import type { Account } from "./account.dto";
import type { CreateAccountResponse } from "./accountResponse.dto";
import type { Order } from "./order.dto";
import type { OrderResponse } from "./orderResponse.dto";
import type { CompatibilityResponse } from "./compatibilityResponse.dto";
import type { PaymentMethod } from "./paymentMethod.dto";
import type { PaymentMethodResponse } from "./paymentMethodResponse.dto";
import type { ActivateResponse } from "./activateResponse.dto";
import type { Promotion, PromoImage } from "./promotion.dto";

const apiUrl = import.meta.env.VITE_API_URL;

async function addCustomerPaymentMethod(
  paymentMethod: PaymentMethod
): Promise<PaymentMethodResponse> {
  const result: PaymentMethodResponse = await fetch(`${apiUrl}/creditcard`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(paymentMethod),
  })
    .then((d) => d.json())
    .catch((e) => console.error("Error adding customer payment method", e));
  return result;
}

async function checkZipcodeCoverage(
  zipcode: string | number
): Promise<Coverage> {
  const result: Coverage = await fetch(`${apiUrl}/coverage?zip=${zipcode}`)
    .then((d) => d.json())
    .catch((e) => console.error("Error checking zipcode coverage", e));
  return result;
}

async function checkDeviceCompatibility(
  deviceId: string | number
): Promise<{ compatible: boolean; response: CompatibilityResponse }> {
  const result: CompatibilityResponse = await fetch(
    `${apiUrl}/device?imei=${deviceId}`
  )
    .then((d) => d.json())
    .catch((e) => console.error("Error checking device compatibility", e));
  return {
    compatible: result.resultCode === 3756 || result.resultCode === 0,
    response: result,
  };
}

async function getPlans(): Promise<Plan[]> {
  const result = await fetch(`${apiUrl}/plans`)
    .then((d) => d.json())
    .then((plans: Plan[]) =>
      plans
        .filter((p) => p.sku !== "TOPUP")
        .map(addPlanComparisonData)
        .sort((a: any, b: any) => a.ordinal - b.ordinal  )
        //.sort((a: any, b: any) => b.savings - a.savings  )
    )
    .catch((e) => console.error("Error fetching plans", e));
  return result as Plan[];
}

async function getPromotions(): Promise<Promotion[]> {
  
  const result = await fetch(`${apiUrl}/promotions`)
    .then((d) => d.json())
    .catch((e) => console.error("Error fetching promotions", e));
    
  return result as Promotion[];
}

async function createCustomerAccount(
  account: Partial<Account>
): Promise<CreateAccountResponse> {
  const result: CreateAccountResponse = await fetch(`${apiUrl}/account`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(account),
  })
    .then((d) => d.json())
    .catch((e) => console.error("Error creating customer account", e));
  return result;
}

/**
 * Use `persist` order boolean to estimate / create order thoughout workflow
 */
async function createCustomerOrder(
  order: Partial<Order>
): Promise<OrderResponse> {
  const result: OrderResponse = await fetch(`${apiUrl}/order`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(order),
  })
    .then((d) => d.json())
    .catch((e) => console.error("Error creating customer account", e));
  return result;
}

export async function activate(activNo:string) : Promise<ActivateResponse> {
  const result: ActivateResponse = await fetch(`${apiUrl}/activate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      activNo: activNo
    }),
  })
    .then((d) => d.json())
    .catch((e) => console.error("Error activating account", e));
  return result;
}

function addPlanComparisonData(plan: Plan, index: number, plans: Plan[]): Plan {
  let compareCost = null,
    savings = null;
    
  const dataGib = plan.title.split(" ")[0];
  const type =
    plan.sku === "TOPUP"
      ? "topup"
      : plan.sku.charAt(plan.sku.length - 1) === "A"
      ? "annual"
      : "monthly";
  if (type === "annual") {
    // Match plan SKU
    const comparePlan = plans.find(
      (p) => p.sku === plan.sku.substring(0, 4) + "T"
    );
    if (comparePlan) {
      compareCost = comparePlan.price;
      savings = compareCost * 12 - plan.price;
    }
    plan.price = plan.price / 12;
  }
  return {
    ...plan,
    dataGib,
    type,
    compareCost,
    savings,
  } as Plan;
}

export {
  apiUrl,
  addCustomerPaymentMethod,
  checkZipcodeCoverage,
  checkDeviceCompatibility,
  createCustomerAccount,
  createCustomerOrder,
  getPlans,
  getPromotions
};
