import { defineStore } from "pinia";

export const useProgressStore = defineStore("progress", {
  state: () => ({
    totalSteps: 0,
    currentStep: 0,
    hide:false
  }),
  getters: {
    percent(state) {
      if (state.totalSteps + state.currentStep === 0) {
        return 0;
      }
      return state.totalSteps && state.currentStep
        ? Math.round((state.currentStep / state.totalSteps) * 100)
        : 0;
    },
  },
});
