import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import { useProgressStore } from "@/stores/progress";

/**
 * Application step route order MATTERS. The routes
 * are used to derive the user's "progress" through
 * the application.
 */
const router = createRouter({
  //history: createWebHashHistory(),
  history: createWebHistory(),
  routes: [
    {
      // Home path is an empty component
      path: "/",
      name: "",
      component: () => import("../views/HomeView.vue"),
    },
    {
      // Modal wrapper component for application steps
      path: "/application",
      name: "Application",
      component: () => import("../views/ApplicationView.vue"),
      children: [
        {
          path: "check-coverage",
          name: "Check Coverage",
          alias: "/application",
          component: () =>
            import("../components/application/steps/CheckCoverage.vue"),
        },
        {
          path: "check-phone",
          name: "Check Phone",
          component: () =>
            import("../components/application/steps/CheckPhone.vue"),
        },
        {
          path: "check-network-compatibility",
          name: "Check Network Compatibility",
          component: () =>
            import(
              "../components/application/steps/CheckNetworkCompatibility.vue"
            ),
        },
        {
          path: "select-plan",
          name: "Select a Plan",
          component: () =>
            import("../components/application/steps/SelectPlan.vue"),
        },
        {
          path: "account-create",
          name: "Create Account",
          component: () =>
            import("../components/application/steps/AccountCreate.vue"),
        },
        {
          path: "account-security",
          name: "Account Security",
          component: () =>
            import("../components/application/steps/AccountSecurity.vue"),
        },
        {
          path: "shopping-cart",
          name: "Shopping Cart",
          component: () =>
            import("../components/application/steps/ShoppingCart.vue"),
        },
        {
          // PATCH functionality is not available for account CRUD
          // so we're skipping this step for the time being.
          path: "billing-details",
          name: "Billing Details",
          redirect: "/application/payment-details",
          // component: () =>
          //   import("../components/application/steps/BillingDetails.vue"),
        },
        {
          path: "payment-details",
          name: "Payment Details",
          component: () =>
            import("../components/application/steps/PaymentDetails.vue"),
        },
        {
          path: "review-order",
          name: "Review Order",
          component: () =>
            import("../components/application/steps/OrderReview.vue"),
        },
        {
          path: "order-confirmed",
          name: "Order Confirmed",
          component: () =>
            import("../components/application/steps/OrderConfirmation.vue"),
        },
        {
          path: "account-error",
          name: "Account Error",
          component: () =>
            import("../components/application/steps/AccountError.vue"),
        },
      ],
    },
  ],
});

// Update progress percentage programatically...
// @TODO hide back button at 100%, clear order information
router.beforeEach((to) => {
  const parentRoute = router.getRoutes().find((r) => r.name === "Application");

  const totalChildRoutes = parentRoute?.children.length;
  const currentRouteIndex = parentRoute?.children.findIndex(
    (c) => c.name === to.name
  );

  if (undefined !== currentRouteIndex && currentRouteIndex > -1) {
    const progress = useProgressStore();
    progress.$patch({
      totalSteps: totalChildRoutes,
      currentStep: currentRouteIndex + 1,
    });
  }
});

export default router;
