import { createApp, watch } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";

import "./assets/stylesheets/main.scss";

const app = createApp(App);

const pinia = createPinia();

// Rehydrate state if present...
let previousState: any = localStorage.getItem("kwApplicationState");
if (previousState !== null) {
  previousState = JSON.parse(previousState);
  pinia.state.value = previousState;
}

// Sync local storage...
watch(
  pinia.state,
  (state) => {
    localStorage.setItem("kwApplicationState", JSON.stringify(state));
  },
  { deep: true }
);

app.use(pinia);

app.use(router);

app.mount("#kwNewCustomerApp");
